"use client";
import React, { useState } from "react";
import { useRouter } from "next/navigation";
import { feathersClient } from "../connection/page";
import { Lato, Roboto, Poppins } from "next/font/google";
import Link from "next/link";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Button } from "../ui/button";
import Notification1 from "@/components/Notification1";

const roboto = Roboto({ weight: ["300", "400", "500", "700", "900"], subsets: ["latin"] });
const poppins = Poppins({ weight: ["300", "400", "500", "600", "700", "900"], subsets: ["latin"] });
const lato = Lato({ weight: "300", subsets: ["latin"] });

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showGooglePopup, setShowGooglePopup] = useState(false);
  const [showFacebookPopup, setShowFacebookPopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    title: '',
    message: '',
    type: 'warning' as 'warning' | 'error' | 'success',
  });

  const router = useRouter();
  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const submitForm = async () => {
    try {
      const credentials = {
        strategy: "local",
        email: email.toLowerCase(),
        password: password,
      };

      const { accessToken, users } = await feathersClient.authenticate(credentials);


      console.log("checkUser1", users);


      // const user = await feathersClient.service('api/v1/users').find({
      //   query: {
      //     _id: users._id,
      //     // $select: ['isVerified', 'role']
      //   }
      // });
      // console.log("response",user);

      // const isVerified = user.data[0].isVerified;
      // console.log("isVerified", isVerified);

      // let admin = user.data[0].role;
      // console.log("role", admin);


      if (users.isVerified == true && users.role == "SUPERADMIN") {

        console.log("Login successfull in ", users);
        localStorage.setItem("feathers-jwt", accessToken);
        localStorage.setItem("users", JSON.stringify(users));
        localStorage.removeItem("buyNow");
        console.log("Login successful. User:", users);
        router.push("/dashboard");

      } else if (users.isVerified == true && users.role.toUpperCase() == "NORMAL") {
        console.log("Login successful. User:", users);
        localStorage.setItem("feathers-jwt", accessToken);
        localStorage.setItem("users", JSON.stringify(users));
        localStorage.removeItem("buyNow");
        router.push("/");

      } else {
        setNotificationProps({
          title: 'Warning Message',
          message: 'Please verify your email address before logging in.',
          type: 'warning',
        });
        setShowNotification(true);
        // alert("Please verify your email address before logging in.")

      }

      // let admin =user.data[0].role;
      // console.log("role",admin);

      // if(admin=="SUPERADMIN"){
      //   console.log("super-admin-loggedin")
      //   router.push("/dashboard");

      // }

    } catch (error: any) {
      console.error("Login failed:", error);

      if (error.message === "Password needs to be reset") {
        setShowPopup(true);
      } else if (error.message === "Google logged in user") {
        setShowGooglePopup(true);
      } else if (error.message === "Facebook logged in user") {
        setShowFacebookPopup(true);
      } else {
        setError("Incorrect email or password. Please try again");
      }
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    submitForm();
  };

  const handlePopupConfirm = () => {
    setShowPopup(false);
    router.push("/forgot-password");
  };

  const handlePopupCancel = () => {
    setShowPopup(false);
    setShowGooglePopup(false);
    setShowFacebookPopup(false);
  };

  const createGoogleOrFacebookPopup = (): JSX.Element => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">

        <div className="absolute w-[539px] max-[466px]:w-[80%] h-[300px] max-[466px]:h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-md shadow-md  z-50 px-4 py-6 flex flex-col justify-around items-center gap-4">
          <div className="flex text-black text-xl font-semibold font-inter">Welcome to the New Karma Tickets!</div>
          <div >
            <span className="text-black text-lg font-medium font-inter">
              It looks like you've previously signed in with your {showGooglePopup && "Google"} {showFacebookPopup && "Facebook"} account. Please log in again using your
            </span>
            <span className="text-orange-500 text-lg text- font-semibold font-inter"> {showGooglePopup && "Google"} {showFacebookPopup && "Facebook"} account.</span>
          </div>
          <div className={`w-full flex gap-2 ${roboto.className}`}>
            <Button
              variant="outline"
              className="w-full text-[#397BEA] border border-[#397BEA] font-bold text-base rounded-[41px]"
              onClick={handlePopupCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outline"
              className="w-full bg-[#E8741C] text-white hover:text-[#E8741C]  font-bold text-base rounded-[41px]">
              <a href={`${showGooglePopup ? "/oauth/google" : "/oauth/facebook"}`} className="text-xl font-light font-Poppins leading-normal tracking-tight">{showGooglePopup && "Log in with Google"}{showFacebookPopup && "Log in with Facebook"}</a>
            </Button>
          </div>
        </div>

      </div>
    );
  };

  return (
    <div className="relative">
      <Notification1
        title={notificationProps.title}
        message={notificationProps.message}
        visible={showNotification}
        onClose={() => setShowNotification(false)}
        type={notificationProps.type}
      />

      <div className={`${poppins.className} flex-col justify-center h-[96%] w-[106%] items-center gap-2 flex`}>
        <div className={`text-zinc-800 flex max-[1100px]:flex-col flex-row gap-2 items-center justify-center text-2xl p-3 lg:text-4xl ${poppins.className} font-thin`}>
          <span className="text-zinc-800 text-2xl lg:text-5xl font-light font-Poppins"   style={{ fontSize: '2.5rem' }}>Welcome to </span>
          <span className="text-orange-500 text-2xl lg:text-5xl font-thin font-Poppins "   style={{ fontSize: '2.5rem' }}>KARMA TICKETS!</span>
        </div>
        <div className={`text-slate-600 text-xl font-medium ${poppins.className} leading-normal tracking-tight mb-2`}>Login to continue</div>

        <div className="w-full px-4 lg:w-2/3 flex gap-6">
          <a href="/oauth/facebook" className="w-full">
            <button className="block w-full">
              <div className="px-4 py-4 w-full rounded border border-gray-400 justify-center items-center gap-2 inline-flex">
                <div className="w-6 h-6 relative">
                  <img src="facebooklogin.svg" alt="Facebook Logo" className="absolute inset-0 w-full h-full" />
                </div>
                <div className="text-black text-sm md:text-base font-normal font-Poppins leading-none">Facebook</div>
              </div>
            </button>
          </a>

          <a href="/oauth/google" className="w-full">
            <button className="block w-full">
              <div className="px-4 py-4 w-full rounded border border-gray-400 justify-center items-center gap-2 inline-flex">
                <div className="w-6 h-6 relative">
                  <img src="googlelogin.png" alt="Google Logo" className="absolute inset-0 w-full h-full" />
                </div>
                <div className="text-black text-sm md:text-base font-normal font-Poppins leading-none">Google</div>
              </div>
            </button>
          </a>
        </div>


        <div className="w-full px-4 lg:w-2/3 flex justify-center flex-col items-center gap-6 md:gap-8">
          <form onSubmit={handleSubmit} className="flex-col justify-center items-center w-full gap-8 inline-flex">
            <div className="gap-4 flex flex-col w-full">
              <div className="self-stretch w-full justify-center items-center gap-3 inline-flex">
                <div className="border-[#CDCED1] border w-full"></div>
                <div className="text-center text-[#747980] text-sm md:text-base font-normal font-Poppins leading-none">OR</div>
                <div className="border-[#CDCED1] border w-full"></div>
                <img src="vector2.svg" alt="vector" className="max-[1168px]:hidden hidden" />
              </div>

              <div className="flex-col justify-start w-full items-start gap-1 flex">
                <label htmlFor="email" className={`text-[#12354D] text-base font-medium ${poppins.className} leading-normal`}>Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleChangeEmail}
                  required
                  className={`text-sky-950 text-base w-full font-normal ${poppins.className} leading-normal h-12 p-4 rounded border border-gray-400 justify-center items-center gap-4 inline-flex`}
                />
              </div>

              <div className="flex-col justify-start w-full items-start gap-1 relative">
                <label htmlFor="password" className={`text-[#12354D] text-base font-medium ${poppins.className} leading-normal`}>Password</label>
                <div className="w-full relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={handleChangePassword}
                    required
                    className={`text-sky-950 text-base w-full font-normal ${poppins.className} leading-normal h-12 p-4 rounded border border-gray-400 pr-12`}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute top-3 right-0 mt-2 mr-3 text-sm text-gray-400"
                  >
                    {showPassword ? <HiEye /> : <HiEyeOff />}
                  </button>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex items-center">

                </div>
                <div className={`text-right text-orange-500 text-sm md:text-base font-semibold ${poppins.className} leading-none tracking-tight`}>
                  <a href="/forgot-password">Forgot Password?</a>
                </div>
              </div>

              <button
                type="submit"
                className={`px-5 py-2 bg-orange-500 rounded-[42px] text-white text-xl ${lato.className} font-medium leading-normal tracking-tight`}
              >
                Login
              </button>

              {error && (
                <div className="error-message text-sm text-center md:text-base text-red-500">
                  {error}
                </div>
              )}
              <div className={`text-center text-sm md:text-base ${poppins.className} font-normal}`}>
                Don't have an account? <span className="text-orange-500 font-semibold"><Link href="/signup"> Sign Up </Link></span>
              </div>
            </div>
          </form>
        </div>

        {showPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className=" px-6 py-4 bg-white rounded-[10px] w-[900px] flex flex-col justify-between gap-9">
              <div className="flex flex-col justify-start items-start">
                <div className="flex text-black text-xl font-semibold font-inter">Welcome to the New Karma Tickets!</div>
                <div className=" text-justify">
                  <span className="text-black text-lg font-medium font-inter">
                    <br />We’ve moved to a new home and brought your account along! To complete the transition and start exploring our new features, please
                  </span>
                  <span className="text-orange-500 text-lg text- font-medium font-inter"> reset your password.</span>
                </div>
                <div className="mx-44 mt-8 flex  justify-center items-center gap-[45px]">
                  <button onClick={handlePopupCancel} className="w-56 h-8 px-5 py-5 bg-gray-400 rounded-xl justify-center items-center gap-2 flex">
                    <div className="text-gray-700 text-xl font-light font-Poppins leading-normal tracking-tight">Cancel</div>
                  </button>
                  <button onClick={handlePopupConfirm} className=" w-56 h-8 px-5 py-5 bg-orange-500 rounded-xl justify-center items-center gap-2 flex">
                    <div className="text-white text-xl font-light font-Poppins leading-normal tracking-tight">Reset Password</div>
                  </button>
                </div>
              </div>

            </div>
          </div>
        )}

        {
          (showGooglePopup || showFacebookPopup) && (<>{createGoogleOrFacebookPopup()}</>)
        }


      </div>
    </div>

  );
};

export default LoginForm;
