import React, { useEffect } from 'react';
import Image from 'next/image';

interface NotificationProps {
    title: string;
    message: string;
    visible: boolean;
    onClose: () => void;
    type?: 'warning' | 'error' | 'success'; // Optional prop for different types of alerts
}

const Notification: React.FC<NotificationProps> = ({ message, visible, onClose, type }) => {
    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                onClose();
            }, 6000); // Hide after 6 seconds

            return () => clearTimeout(timer);
        }
    }, [visible, onClose]);

    const getImageSrc = () => {
        switch (type) {
            case 'success':
                return '/CORRECT1.png';
            case 'warning':
                return '/WARNING.png';
            // Add more cases if needed
            default:
                return ''; // Default to no image if type is not matched
        }
    };

    return (
        <div
            className={`fixed top-0 right-0 m-4 transition-opacity duration-300 ${visible ? 'opacity-100' : 'opacity-0'}`}
        >
            <div className={`w-full max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl p-4 rounded-lg flex gap-4 items-start 
                ${type === 'success' ? 'bg-green-100 border border-green-600' : 'bg-yellow-50 border border-amber-500'}`}>
                {type && (
                    <Image src={getImageSrc()} alt={`${type} icon`} width={30} height={30} />
                )}
                <div className="flex flex-col gap-1">
                    <div className="text-gray-800 text-sm md:text-base font-semibold leading-tight">
                        {type === 'warning' ? 'Warning Message' : 'Success Message'} 
                    </div>
                    <div className="text-gray-500 text-sm md:text-base font-normal leading-tight">
                        {message}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notification;
